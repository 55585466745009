/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Link, navigate } from "gatsby";
import { Box, Flex } from '../../style/basicStyle';
import toast from '../../utils/toast';
import { ButtonSecondary } from '../Buttons/index';
import { withFirebase } from '../Firebase';
import feather from "../../utils/feather";
import { getHiddenColumns, IndeterminateCheckbox, returnCurrentSeason } from "../TableFilters/TableFilters";
import { TableStyles, IconInline } from '../../style/tableStyling.js'

function Table({ columns, data, initialState }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    getToggleHideAllColumnsProps,
    allColumns
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ],
        hiddenColumns: ['docId'].concat(getHiddenColumns(columns)),
        filters: [
          {
            id: 'season',
            value: returnCurrentSeason(data),
          },
        ],
      }
    },
    useSortBy,
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
        </div>
        {/* Loop through columns data to create checkbox */}
        {allColumns.map((column) => (
          <div className="cb action" key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              <span>{column.Header}</span>
            </label>
          </div>
        ))}
        <br />
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
}

function processPayBalance(tuition, idToken) {
  // console.log('processPayBalance: tuition var is: ', tuition);
  const payingBalancePaypal = tuition && tuition.tuitionCost === 'full' && true;
  const programCost = tuition.balance;
  const values = { tuition, idToken, programCost, payingBalancePaypal };
  const enabled = true;
  toast('Processing payment request...');
  if (!payingBalancePaypal) {
    const { partiallyUpdate } = tuition;
    const { installment } = tuition;
    if (!partiallyUpdate) {
      // customer never started the Partially form update process.
      const { docId } = tuition;
      const url = `${(tuition.devMode && installment.installmentUrlDemo) || installment.installmentUrl}&meta[sourceDocId]=${docId}`;
      const redirectPayload = {
        pageTitle: 'Processing Payment...',
        redirectUrlTitle: 'Partial.ly',
        redirectUrl: url
      };
      // console.log('tuition form success - submitted values: ', tuitionValues, ' | invoiceUrl: ', invoiceUrl, ' | redirectPayload: ', redirectPayload);
      navigate(`/success?payload=${encodeURIComponent(JSON.stringify(redirectPayload))}`);
    } else {
      const paymentPlanId = tuition.partiallyUpdate && tuition.partiallyUpdate.data && tuition.partiallyUpdate.data.payment_plan && tuition.partiallyUpdate.data.payment_plan.id;
      if (!paymentPlanId) {
        const errorMessage = 'Unable to find Partial.ly Payment Plan ID';
        const errorPayload = {
          pageTitle: 'Error Processing...',
          errorMessage
        };
        console.log('processPayBalance form ERROR 2 - submitted values: ', values, ' | error is: ', errorMessage);
        navigate(`/error?payload=${encodeURIComponent(JSON.stringify(errorPayload))}`);
      } else {
        let baseUrl;
        if (tuition.devMode) {
          baseUrl = `https://demo.partial.ly`;
        } else {
          baseUrl = `https://partial.ly`;
        }
        const url = `${baseUrl}/customer/plan/${paymentPlanId}`;
        const redirectPayload = {
          pageTitle: 'Processing Payment...',
          redirectUrlTitle: 'Partial.ly',
          redirectUrl: url
        };
        // console.log('tuition form success - submitted values: ', tuitionValues, ' | invoiceUrl: ', invoiceUrl, ' | redirectPayload: ', redirectPayload);
        navigate(`/success?payload=${encodeURIComponent(JSON.stringify(redirectPayload))}`);
      }
    }
  } else {
    if (enabled) {
      // console.log('tuition: paying paypal balance details: ', values);
      fetch('/.netlify/functions/tuition2023', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      })
        .then((result2) => {
          try {
            const resultJSON = result2.json();
            resultJSON
              .then(function processResult(r) {
                if (result2.status === 200) {
                  const invoiceUrl = r.message;
                  const redirectPayload = {
                    pageTitle: 'Processing Payment...',
                    redirectUrlTitle: 'Paypal',
                    redirectUrl: invoiceUrl
                  };
                  // console.log('tuition form success - submitted values: ', tuitionValues, ' | invoiceUrl: ', invoiceUrl, ' | redirectPayload: ', redirectPayload);
                  navigate(`/success?payload=${encodeURIComponent(JSON.stringify(redirectPayload))}`);
                } else {
                  const errorMessage = r.message;
                  const errorPayload = {
                    pageTitle: 'Error Processing...',
                    errorMessage
                  };
                  console.log('processPayBalance form ERROR 1 - submitted values: ', values, ' | error is: ', r);
                  navigate(`/error?payload=${encodeURIComponent(JSON.stringify(errorPayload))}`);
                }
              })
              .catch((error) => {
                const errorMessage = error.message;
                const errorPayload = {
                  pageTitle: 'Error Processing...',
                  errorMessage
                };
                console.log('processPayBalance form ERROR 2 - submitted values: ', values, ' | error is: ', error);
                navigate(`/error?payload=${encodeURIComponent(JSON.stringify(errorPayload))}`);
              });
          } catch (e) {
            console.log('processPayBalance form ERROR 22 - submitted values: ', values, ' | res is: ', result2, ' | error is: ', e);
            navigate('/error');
          }
        })
        .catch((error) => {
          console.log('processPayBalance form ERROR 3 - submitted values: ', values, ' | error is: ', error);
          navigate('/error');
        });
    }
  }
}

function payBalance(tuition, idToken) {
  let payVal = 'Pay Balance';
  if (tuition) {
    if ((tuition.tuitionCost === 'installment' && tuition.partiallyUpdate) || tuition.manualDeposit) {
      // manualDeposit exists for 9 records that were created without the partially Webhook registered
      payVal = 'Manage Installment';
    } else if (tuition.tuitionCost === 'installment' && !tuition.partiallyUpdate) {
      payVal = 'Pay Deposit';
    }
  }
  return (
    <ButtonSecondary onClick={() => processPayBalance(tuition, idToken)} style={{ textDecoration: 'underline' }}>
      {payVal}
    </ButtonSecondary>
  );
}
const tuitionRecord = (tuition, idToken, authUser) => {
  return {
    idToken,
    authUser,
    docId: tuition.docId,
    name: tuition.name,
    momEmail: tuition.momEmail,
    dadEmail: tuition.dadEmail,
    momPhone: tuition.momPhone,
    dadPhone: tuition.dadPhone,
    playerName: tuition.playerName,
    playerDob: tuition.playerDob,
    division: tuition.division,
    season: tuition.seasonYear,
    balance: `$${tuition.balance}`,
    programCost: tuition.programCost,
    paid: (tuition.paid && 'Paid') || payBalance(tuition, idToken),
    paidDate: tuition.paidDate,
    updatedAt: tuition.updatedAt,
    paidDateSort: tuition.paidDateSort,
    type: (tuition.practice && 'Practice') || 'Tuition'
  };
};

const columns = [
  {
    Header: 'Tuition',
    columns: [
      {
        Header: 'Player Name',
        accessor: 'playerName'
      },
      {
        Header: 'Player DOB',
        accessor: 'playerDob'
      },
      {
        Header: 'Division',
        accessor: 'division'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Season',
        accessor: 'season'
      },
      {
        Header: 'Payment Status',
        accessor: 'paid'
      },
      {
        Header: 'Paid Date',
        accessor: 'paidDateSort',
        Cell: ({ row: { original } }) => {
          let { paidDate } = original;
          let { paidDateSort } = original;
          let sortResults;
          try {
            sortResults = paidDateSort && (
              <span>{paidDate}</span>
            );
          } catch (e) {
            console.log('paidDateSort CELL error: ', e);
          }
          return sortResults || null;
        }
      },
      {
        isVisible: false,
        Header: 'Action',
        accessor: 'docId',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { docId } = original;
          const { token } = original;
          const { authUser } = original;
          const action = docId && (
            <IconInline>
              <Link target="_blank" state={{ admin: true }} to={`/viewOrder/${docId}`}>
                {feather('eye', ['25', '25'])}
              </Link>
              {authUser && authUser.roles && authUser.roles.CHANGEPAYMENT && (<a rel="noreferrer" target="_blank" href={`https://app.snipcart.com/dashboard/orders/${token}`}>{feather('edit', ['25', '25'])}</a>)}
            </IconInline>
          );
          return action || null;
        }
      }
    ]
  }
];

class Tuition extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      idToken: undefined,
      loading: false,
      tuitionList: []
    };
  }

  componentDidMount() {
    this.getTuitionList();
  }

  componentDidUpdate() {
    this.getTuitionList();
  }

  getTuitionList = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.firebase.auth.currentUser
            // eslint-disable-next-line react/prop-types
            .getIdToken(/* forceRefresh */ true)
            .then(function getIdToken(idToken) {
              const values = { idToken };
              fetch('/.netlify/functions/getTuition', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              })
                .then((result) => {
                  try {
                    const resultJSON = result.json();
                    resultJSON
                      .then(function processResult(r) {
                        if (result.status === 200) {
                          self.setState({
                            // eslint-disable-next-line react/no-unused-state
                            tuitionList: r.message,
                            loading: false,
                            idToken
                          });
                        } else {
                          console.log('tuition request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                          toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                        }
                      })
                      .catch((error) => {
                        console.log('tuition request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                        toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                      });
                  } catch (e) {
                    console.log('tuition request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                    toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                })
                .catch((error) => {
                  console.log('tuition request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                  toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                });
            })
            .catch(function getIdTokenError(error) {
              console.log('tuition - getIdTokenError - error is: ', error);
            });
        }
      });
    }
  };

  formatTable() {
    const { state } = this;
    const { props } = this;
    const { idToken } = state;
    const authUser = props && props.firebase && props.firebase.authUser;
    const { tuitionList } = state;
    const length = tuitionList && tuitionList.length;
    if (length) {
      const formatTableLevel = (depth = 0) => {
        return tuitionList.map((tuition) => {
          return {
            ...tuitionRecord(tuition, idToken, authUser),
            subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
          };
        });
      };
      return formatTableLevel();
    }
    return [];
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        {loading && <div>Loading ...</div>}
        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(Tuition);
