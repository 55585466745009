/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { compose } from 'recompose';
import styledSystem from '@emotion/styled';
import { toast } from 'react-toastify';
import { graphql, navigate } from 'gatsby';
import LayoutWithAuth from '../components/layoutWithAuth';
import { withAuthorization, withEmailVerification } from '../components/Session';
import { Tuition } from '../components/Tuition';
import { Box, Flex } from '../style/basicStyle';

const TuitionPageBase = (props) => {
  return (
    <Box px={[2, 3, 5]}>
      <h3>Tuition</h3>
      <Tuition />
    </Box>
  );
};

const condition = (authUser) => authUser;

const TuitionPage = compose(withEmailVerification, withAuthorization(condition))(TuitionPageBase);

const Named = ({ toggleNavDark, location }) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <TuitionPage />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query tuitionContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "tuition" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
